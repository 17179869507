<template>
  <div class="home">
    <el-row gutter="30">
      <el-col :span="12">
        <el-row>
          <el-image style="width: 500px; height: 500px;" :src="errImg" :fit="cover"></el-image>
        </el-row>
        <el-row style="width: 500px; text-align: center; font-size: 24px; color: #909399;">找不到页面 !</el-row>
      </el-col>
    </el-row>    
  </div>
</template>
<script>
import errImg from "../../../assets/images/404.png";
export default {
  data() {
    return {
      errImg,
    }
  }
}
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
